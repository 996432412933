import React, { useContext, useEffect, useState } from "react";
import * as api from "apis/FusionAPI";
import { usePerms } from "hooks/usePerms";
import { ColorContext } from "App";
import { groups } from "d3";
import ConfirmPopup from "components/UI/ConfirmPopup";
import { MapContainer, TileLayer } from "react-leaflet";
import LocationSetter from "components/AlertCreator/components/LocationSetter";
import PlacesSearch from "components/PlacesSearch";
import Cookies from "js-cookie";
import { ConnectionsContext } from "pages/System/Connections";
import RoadDefinerMap from "./RoadDefinerMap";

const NewDevice = ({
  setShowNewDevice,
  setDevices,
  integrations,
  initialData,
  setInitialData,
  deviceTypes,
  deviceGroups,
  setDeviceGroups,
}) => {
  const connectionsContext = useContext(ConnectionsContext);
  const theme = useContext(ColorContext);
  const [mapCenter, setMapCenter] = useState([
    Cookies.get("latitude"),
    Cookies.get("longitude"),
  ]);
  const [zoom, setZoom] = useState(11);
  const [data, setData] = useState({
    system_type: "",
    system_name: "",
    device_type: "",
    name: "",
    alias: "",
    IMEI: "",
    uuid: "",
    node_id: "",
    accessToken: null,
    groups: [],
  });

  const [showDelete, setShowDelete] = useState(false);

  const perms = usePerms();

  const createDevice = async () => {
    if (
      (data.system_type === "AWARE" &&
        (!data.uuid || !data.node_id || !data.name || !data.alias)) ||
      (data.system_type === "Manual Device" &&
        (!data.road_id || !data.alias || !data.name))
    )
      return alert("Please fill all fields");

    let res;
    if (initialData) {
      res = await api.updateConnectedDevice(data);
    } else {
      res = await api.addConnectedDevice(data);
    }
    if (res.status === 200) {
      setShowNewDevice(false);
      setInitialData(null);

      connectionsContext.getDevices();
      connectionsContext.getDeviceData();
    } else {
      alert("Error creating connection");
    }
  };

  useEffect(() => {
    if (initialData) {
      setData({
        ...initialData,
        groups: deviceGroups
          .filter((group) => {
            return JSON.parse(group.devices).includes(initialData.id);
          })
          .map((group) => group.id),
      });
    }
  }, []);

  const removeDevice = async () => {
    if (initialData) {
      const res = await api.removeConnectedDevice(initialData);
      if (res.status === 200) {
        setShowNewDevice(false);
        setShowDelete(false);
        setInitialData(null);
        connectionsContext.getDevices();
        connectionsContext.getDeviceData();
      }
    }
  };

  const addLocation = (location) => {
    setMapCenter([
      location.geometry.location.lat,
      location.geometry.location.lng,
    ]);
    setData({
      ...data,
      location: `${[
        location.geometry.location.lat,
        location.geometry.location.lng,
      ]}`,
    });
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        color: theme.fontColor,
        background: theme.base,
        padding: 20,
        borderRadius: 10,
        border: "1px solid #ccc",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
          {initialData ? "Edit Device" : "New Device"}
        </div>
        <div
          style={{ cursor: "pointer", fontWeight: "bold", fontSize: "1.5rem" }}
          onClick={() => setShowNewDevice(false)}
        >
          X
        </div>
      </div>

      <form style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <div
          style={{ flex: 1, display: "flex", flexDirection: "column", gap: 10 }}
        >
          <label style={{ fontSize: "1rem" }}>System</label>
          <select
            value={data.system_type}
            onChange={(e) => {
              if (!e.target.value) {
                setData({
                  ...data,
                  system_type: "",
                });
                return;
              }
              //find system from integrations
              let system = integrations.find(
                (integration) => integration.system_type === e.target.value
              );
              setData({
                ...data,
                system_type: system.system_type,
              });
            }}
            style={{
              background: theme.primary,
              height: 40,
              borderRadius: 10,
              paddingLeft: 10,
              fontSize: "1rem",
            }}
          >
            <option value="">Select System</option>
            {integrations.map((integration) => (
              <option
                key={integration.system_name}
                value={integration.system_type}
              >
                {integration.system_name}
              </option>
            ))}
          </select>
          {data.system_type && (
            <>
              <label style={{ fontSize: "1rem", marginTop: 5 }}>
                Device Type
              </label>
              <select
                value={data.device_type}
                onChange={(e) => {
                  if (!e.target.value) {
                    setData({
                      ...data,
                      device_type: "",
                    });
                    return;
                  }
                  setData({
                    ...data,
                    device_type: e.target.value,
                  });
                }}
                style={{
                  background: theme.primary,
                  height: 40,
                  borderRadius: 10,
                  paddingLeft: 10,
                  fontSize: "1rem",
                }}
              >
                <option value="">Select Device Type</option>
                {(deviceTypes[data.system_type] || []).map((device_type) => (
                  <option key={device_type} value={device_type}>
                    {device_type}
                  </option>
                ))}
              </select>
            </>
          )}

          {data.system_type === "AWARE" && data.device_type === "Flood" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 10,
                // backgroundColor: "rgba(255,0,0,.5)",
              }}
            >
            <label>UUID</label>
            <input
              type="text"
              style={{
                background: theme.primary,
                height: 40,
                marginTop: 10,
                marginBottom: 20,
                borderRadius: 10,
                paddingLeft: 15,
              }}
              value={data.uuid}
              onChange={(e) =>
                setData({
                  ...data,
                  uuid: e.target.value,
                })
              }
            />
            <label>IMEI</label>
            <input
              type="text"
              style={{
                background: theme.primary,
                height: 40,
                marginTop: 10,
                marginBottom: 20,
                borderRadius: 10,
                paddingLeft: 15,
              }}
              value={data.IMEI}
              onChange={(e) =>
                setData({
                  ...data,
                  IMEI: e.target.value,
                })
              }
            />

              <label>Node ID</label>
              <input
                type="text"
                style={{
                  background: theme.primary,
                  height: 40,
                  marginTop: 10,
                  marginBottom: 20,
                  borderRadius: 10,
                  paddingLeft: 15,
                }}
                value={data.node_id}
                onChange={(e) =>
                  setData({
                    ...data,
                    node_id: e.target.value,
                  })
                }
              />
              <label>Entity Name</label>
              <input
                type="text"
                style={{
                  background: initialData ? "#454545" : theme.primary,
                  height: 40,
                  marginTop: 10,
                  marginBottom: 20,
                  borderRadius: 10,
                  paddingLeft: 15,
                }}
                value={data.name}
                disabled={initialData}
                onChange={(e) =>
                  setData({
                    ...data,
                    name: e.target.value,
                  })
                }
              />
              <label>Device Alias</label>
              <input
                type="text"
                style={{
                  background: theme.primary,
                  height: 40,
                  marginTop: 10,
                  marginBottom: 20,
                  borderRadius: 10,
                  paddingLeft: 15,
                }}
                value={data.alias}
                onChange={(e) =>
                  setData({
                    ...data,
                    alias: e.target.value,
                  })
                }
              />
              <label>Node Key (optional)</label>
              <input
                type="text"
                style={{
                  background: theme.primary,
                  height: 40,
                  marginTop: 10,
                  marginBottom: 30,
                  borderRadius: 10,
                  paddingLeft: 15,
                }}
                value={data.node_key}
                onChange={(e) =>
                  setData({
                    ...data,
                    node_key: e.target.value,
                  })
                }
              />
            </div>
          )}

          {data.device_type === "Flashing Lights" && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 10,
                  // backgroundColor: "rgba(255,0,0,.5)",
                }}
              >
                <label>Road ID</label>
                <input
                  type="text"
                  style={{
                    background: theme.primary,
                    height: 40,
                    marginTop: 10,
                    marginBottom: 20,
                    borderRadius: 10,
                    paddingLeft: 15,
                  }}
                  value={data.road_id}
                  onChange={(e) =>
                    setData({
                      ...data,
                      road_id: e.target.value,
                    })
                  }
                />
                <label>Entity Name</label>
                <input
                  type="text"
                  style={{
                    background: initialData ? "#454545" : theme.primary,
                    height: 40,
                    marginTop: 10,
                    marginBottom: 20,
                    borderRadius: 10,
                    paddingLeft: 15,
                  }}
                  disabled={initialData}
                  value={data.name}
                  onChange={(e) =>
                    setData({
                      ...data,
                      name: e.target.value,
                    })
                  }
                />
                <label>Device Alias</label>
                <input
                  type="text"
                  style={{
                    background: theme.primary,
                    height: 40,
                    marginTop: 10,
                    marginBottom: 20,
                    borderRadius: 10,
                    paddingLeft: 15,
                  }}
                  value={data.alias}
                  onChange={(e) =>
                    setData({
                      ...data,
                      alias: e.target.value,
                    })
                  }
                />
              </div>
              <div style={{ marginLeft: 10, marginTop: 10 }}>
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 10,
                  }}
                >
                  <label>Location</label>
                  <PlacesSearch
                    noBack
                    noAutoAdd
                    places={[]}
                    setSearching={() => {}}
                    addLocation={addLocation}
                    setMapCenter={setMapCenter}
                    quickSave
                  />
                </div> */}
                <div style={{ width: "100%", height: 300 }}>
                  <RoadDefinerMap />
                  {/* <MapContainer
                    center={mapCenter}
                    zoom={11}
                    style={{ height: "100%", width: "100%" }}
                  >
                    <LocationSetter center={mapCenter} zoom={zoom} />
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                  </MapContainer> */}
                </div>
                {/* <div>
                  <input
                    type="text"
                    style={{
                      background: theme.primary,
                      height: 40,
                      marginTop: 10,
                      marginBottom: 20,
                      borderRadius: 10,
                      paddingLeft: 15,
                    }}
                    value={data.location}
                    onChange={(e) =>
                      setData({
                        ...data,
                        location: e.target.value,
                      })
                    }
                  />
                </div> */}
              </div>
            </div>
          )}
        </div>
        <label>Device Groups (optional)</label>
        <div
          style={{
            marginTop: 10,
            padding: 10,
            background: theme.primary,
            borderRadius: 10,
            maxHeight: 200,
            overflowY: "auto",
            gap: 10,
          }}
        >
          {deviceGroups.map((group) => (
            <div
              key={group.id}
              style={{
                display: "flex",
                alignItems: "center",
                background: data.groups.includes(group.id)
                  ? theme.primaryShadow
                  : "",
                gap: 10,
                padding: 5,
                marginTop: 5,
                borderRadius: 5,
              }}
            >
              <input
                type="checkbox"
                checked={data.groups.includes(group.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setData({
                      ...data,
                      groups: [...data.groups, group.id],
                    });
                  } else {
                    setData({
                      ...data,
                      groups: data.groups.filter((g) => g !== group.id),
                    });
                  }
                }}
              ></input>
              <div>{group.name}</div>
            </div>
          ))}
        </div>
      </form>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 20,
        }}
      >
        {" "}
        {initialData ? (
          <div
            style={{
              cursor: "pointer",
              backgroundColor: "red",
              fontWeight: "bold",
              width: "fit-content",
              padding: "15px 30px",
              borderRadius: 10,
              fontSize: "1rem",
            }}
            onClick={() => setShowDelete(true)}
          >
            Remove
          </div>
        ) : (
          <div></div>
        )}
        <div
          style={{
            cursor: "pointer",
            backgroundColor: theme.primaryHighlight,
            fontWeight: "bold",
            width: "fit-content",
            padding: "15px 30px",
            borderRadius: 10,
            fontSize: "1rem",
          }}
          onClick={() => createDevice()}
        >
          {initialData ? "Save" : "Create"}
        </div>
      </div>
      {showDelete && (
        <ConfirmPopup
          open={showDelete}
          setOpen={setShowDelete}
          message={
            <>
              <h2>Warning: Permanent Removal of Device</h2>
              You are about to remove a device. This action is permanent and
              cannot be undone. Only the selected device will be affected.
              Please confirm you wish to proceed with this action.
            </>
          }
          options={["Cancel", "Remove"]}
          callbacks={[
            () => {
              setShowDelete(false);
            },
            () => {
              removeDevice();
            },
          ]}
          closeAction={() => {
            setShowDelete(false);
          }}
          buttonStyles={[
            { backgroundColor: theme.background, color: theme.fontColor },
            { backgroundColor: "red", color: theme.fontColor },
          ]}
        />
      )}

      {/* This looks wild. What is it? - Brenton */}
      <style jsx>{`
        @media (max-width: 768px) {
          div[style*="flex-direction: column"] {
            flex-direction: column;
          }
          div[style*="flex-direction: row"] {
            flex-direction: column;
          }
          select,
          input {
            width: 100%;
          }
          div[style*="flex-direction: row"] > div {
            margin-top: 10px;
          }
          div[style*="justify-content: flex-end"] {
            justify-content: center;
          }
        }

        @media (max-width: 480px) {
          div[style*="font-size: 1.5rem"] {
            font-size: 1.2rem;
          }
          div[style*="font-size: 1rem"] {
            font-size: 0.9rem;
          }
          input,
          select {
            font-size: 0.9rem;
            height: 35px;
          }
          div[style*="padding: 15px 30px"] {
            padding: 10px 20px;
          }
        }
      `}</style>
    </div>
  );
};

export default NewDevice;
